.heading {
    font-size: 1.15em;
    font-weight: 700;
    text-wrap: balance;
}

.label-modal {
    font-weight: 700;
    font-size: 1.2em;
    color: var(--primary);
}