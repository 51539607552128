@use "sass:map";
@use '../breakpoints';

@each $bp, $value in breakpoints.$config {
    $bp-min: map.get($value, 'min');
    $bp-max: map.get($value, 'max');

    @if $bp-min {
        @media screen and (min-width: $bp-min) {
            .#{$bp}\:hidden {
                display: none;
            }
            .#{$bp}\:visible {
                display: revert;
            }
        }
    }

    @if $bp-max {
        @media screen and (max-width: $bp-max) {
            .#{$bp}\:hidden {
                display: none;
            }
            .#{$bp}\:visible {
                display: revert;
            }
        }
    }
}
