$family-root: "Nunito", system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
$size-root: 10px !default;
$size-base: 16px !default;
$line-height-base: 1.6 !default;

/**
* Nunito variable
*/
@font-face {
    font-family: 'Nunito';
    src: url('../fonts/Nunito-VariableFont_wght.ttf') format('woff2') tech('variations'),
        url('../fonts/Nunito-VariableFont_wght.ttf') format('woff2-variations');
    font-weight: 400 1000;
    font-stretch: 25% 151%;
    font-style: normal;
}
@font-face {
    font-family: 'Nunito';
    src: url('../fonts/Nunito-Italic-VariableFont_wght.ttf') format('woff2') tech('variations'),
        url('../fonts/Nunito-Italic-VariableFont_wght.ttf') format('woff2-variations');
    font-weight: 400 1000;
    font-stretch: 25% 151%;
    font-style: italic;
}

@font-face {
    font-family: 'icomoon';
    src:  url('../fonts/icomoon.eot?pcwd7e');
    src:  url('../fonts/icomoon.eot?pcwd7e#iefix') format('embedded-opentype'),
      url('../fonts/icomoon.woff2?pcwd7e') format('woff2'),
      url('../fonts/icomoon.ttf?pcwd7e') format('truetype'),
      url('../fonts/icomoon.woff?pcwd7e') format('woff'),
      url('../fonts/icomoon.svg?pcwd7e#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
  }

  [class^="icon-"], [class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    > *::before {
        transition-property: color;
        transition-duration: .3s;
        transition-timing-function: ease-in-out;
    }
  }

  .icon-times:before {
    content: "\e91d";
  }
  .icon-balance:before {
    content: "\e918";
  }
  .icon-sign:before {
    content: "\e919";
  }
  .icon-phone:before {
    content: "\e91a";
  }
  .icon-link:before {
    content: "\e91b";
  }
  .icon-hand:before {
    content: "\e91c";
  }
  .icon-burger:before {
    content: "\e914";
  }
  .icon-user-star .path1:before {
    content: "\e915";
    color: rgb(0, 0, 0);
  }
  .icon-user-star .path2:before {
    content: "\e916";
    margin-left: -1em;
    color: rgb(0, 0, 0);
  }
  .icon-user-star .path3:before {
    content: "\e917";
    margin-left: -1em;
  }
  .icon-minus:before {
    content: "\e90a";
  }
  .icon-plus:before {
    content: "\e90b";
  }
  .icon-arrow-right:before {
    content: "\e913";
  }
  .icon-caret-left:before {
    content: "\e90c";
  }
  .icon-map:before {
    content: "\e911";
  }
  .icon-pin:before {
    content: "\e910";
  }
  .icon-geoloc:before {
    content: "\e912";
  }
  .icon-parking:before {
    content: "\e90e";
  }
  .icon-bus:before {
    content: "\e90f";
  }
  .icon-city:before {
    content: "\e908";
  }
  .icon-download:before {
    content: "\e903";
  }
  .icon-photo:before {
    content: "\e904";
  }
  .icon-check:before {
    content: "\e905";
  }
  .icon-star:before {
    content: "\e902";
  }
  .icon-info:before {
    content: "\e906";
  }
  .icon-shield:before {
    content: "\e907";
  }
  .icon-walk:before {
    content: "\e909";
  }
  .icon-zone:before {
    content: "\e90d";
  }
  .icon-sad-face:before {
    content: "\e900";
  }
  .icon-happy-face:before {
    content: "\e901";
  }
