.alert {
    padding: 1.6rem;
    border-radius: .8rem;
    
    &-primary {
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100%;
        background-color: var(--primary-shadow);
    }
}

.checklist {
    list-style-type: none;
    padding: 0;
    margin: 0;
    font-size: .9em;

    li {
        position: relative;
        padding-inline-start: 3.5rem;

        &::before {
            display: block;
            content: "\e905";
            position: absolute;
            top: 0;
            left: -.5rem;
            font-family: 'icomoon';
            font-size: 2em;
            line-height: 1;
            color: var(--primary);
        }
    }
}
