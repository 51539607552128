.button-list {
    list-style-type: none;
    padding: 0;
    margin: 0 calc(var(--overlay-padding-x) * -1) 0 0;
    padding-right: var(--overlay-padding-x);
    display: flex;
    flex-flow: row nowrap;
    gap: .8rem;
    overflow-x: auto;
    scroll-behavior: smooth;

    li {
        button {
            height: 100%;
        }
    }
}
