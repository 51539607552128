@use '../breakpoints';

.logo-ville-marchable {

    position: absolute;
    display: flex;
    top: 0;
    left: 0;
    display: flex;
    padding-top: 2rem;
    padding-left: 10rem;
    @media screen and (width < breakpoints.$mobile) {
        padding-top: 0;
        padding-left: 6.5rem;
    }

    .logo {
        height: 7rem;
        width: 25rem;
        @media screen and (width < breakpoints.$mobile) {
            height: 8rem;
            width: 13rem;
        }
    }
}