@use '../colors';

.chipicon {
    display: inline-block;
    padding: .5rem;
    font-size: 1.8em;
    color: var(--primary);
    background-color: var(--primary-shadow-l);
    border-radius: 50%;
}

.chipicon-contact {
    display: inline-block;
    padding: .5rem;
    font-size: 2em;
    background-color: var(--primary);
    color: colors.$white;
    border-radius: 50%;
}
