@use '../breakpoints';

details {
    border-radius: 1.6rem;

    summary {
        display: flex;
        align-items: center;
        cursor: pointer;

        .heading {
            .heading-label {
                position: relative;

                &::after {
                    position: absolute;
                    top: 50%;
                    left: calc(100% + 0.75ch);
                    transform: translateY(-50%);
                    content: "\e90c";
                    font-family: 'icomoon';
                    font-size: 1em;
                }
            }
        }

        + * {
            display: block;
            margin-top: .75em;
        }
    }

    &:not([open]) summary .heading .heading-label::after {
        transform: translateY(-50%) rotate(270deg);

    }
    &[open] summary .heading .heading-label::after {
        transform: translateY(-50%) rotate(90deg);

    }
}

.contact-container {
    @media screen and (width < breakpoints.$mobile) {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-top: 2em;
        row-gap: 2.5em;
        
    }
    display: flex;
    justify-content: space-around;
    column-gap: 1rem;
    .contact-section {
        display: flex;
        width: 100%;
        flex-direction: column;
        align-items: center;
        padding: .5rem;
        row-gap: 2rem;
        .label-container {
            display: flex;
            flex-direction: column;
            row-gap: .1em;
            justify-content: center;
            align-items: center;
        }
    }

    h3 {
        font-weight: 600;
        font-size: 1.1em;
    }

    a {
        text-decoration: none;
        color: black;
        &:hover {
            text-decoration: underline;
            color: var(--primary);
        }
    }
}

.maplibregl-ctrl-attrib {
    font-size: .8em;
    border-radius: 0;
}

.maplibregl-ctrl-attrib-inner   {
    margin-top: 0;
}
