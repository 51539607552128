$white:     #FFF;
$gray-100:  #ebebeb;
$gray-400:  #BDBDBD;
$gray-500:  #828282;
$black:     #000;

$red-400: #EB5757;
$red-500:   #CC4533;
$blue-500:  #387D9F;

$green-500: #27AE60;

$yellow-500: #FBDA5A;

$theme-rennes: (
    "white": $white,
    "gray-100": $gray-100,
    "gray-400": $gray-400,
    "gray-500": $gray-500,
    "black":  $black,
    "black-shadow": rgba($black, 0.2),

    "body": $black,
    "bg-body": $white,

    //"primary": $red-500,
    // "primary-dark": darken($red-500, 10%),
    // "primary-shadow": rgba($red-500, 30%),
    // "primary-shadow-l": rgba($red-500, 20%),

    "red": $red-400,
    "green": $green-500,
    "yellow": $yellow-500,
    "highlight": $blue-500,
);

$theme-nantes: (
    "white": $white,
    "gray-100": $gray-100,
    "gray-400": $gray-400,
    "gray-500": $gray-500,
    "black":  $black,
    "black-shadow": rgba($black, 0.1),

    "body": $black,
    "bg-body": $white,

    "primary": $blue-500,
    "primary-dark": darken($blue-500, 10%),
    "primary-shadow": rgba($blue-500, 30%),
    "primary-shadow-l": rgba($blue-500, 20%),

    "red": $red-400,
    "green": $green-500,

    "highlight": $blue-500,
);

/**
* Exposes colors to the DOM in case you need to reference a color outside of .scss files
*/
:root {
    @each $name, $color in $theme-rennes {
        --#{$name}: #{$color};
    }
}

.theme-nantes:root {
    @each $name, $color in $theme-nantes {
        --#{$name}: #{$color};
    }
}
