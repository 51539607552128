/**
* == Classes utilitaires à utiliser sur des éléments isolés ==
*/
@use './colors';

.hidden {
    display: none !important;
    visibility: hidden;
}

.visually-hidden {
    position: absolute !important;
    clip: rect(1px, 1px, 1px, 1px);
    padding: 0 !important;
    border: 0 !important;
    height: 1px !important;
    width: 1px !important;
    overflow: hidden;
}

.relative {
    position: relative;
}

img {
    max-width: 100%;
    height: auto;

    &.align-right {
        float: right;
        margin-left: 3rem;
    }

    &.align-left {
        float: left;
        margin-right: 3rem;
    }

    &.align-center {
        margin: 2rem auto;
        display: table;
    }
}

.no-touch {
    a:not(.btn) {
        transition: all 300ms ease;
        opacity: 1;

        &:hover {
            opacity: 0.7;
        }
    }
}

.active-link {
    position: relative;

    a {
        &::after {
            content: "";
            position: absolute;
            z-index: 1;
            inset: 0;
        }
    }

    .title {
        transition: all 300ms ease;
    }
}

.active-link-photo {
    .photo {
        overflow: hidden;

        img {
            transition: all 300ms ease;
            display: block;
        }
    }
}

.no-touch {
    .active-link-photo {
        &:hover {
            .photo {
                img {
                    transition: all 300ms ease;
                    transform: scale(1.05);
                }
            }
        }
    }
}

.txt {
    &-right {
        text-align: right;
    }

    &-center {
        text-align: center;
    }

    &-left {
        text-align: left;
    }

    &-primary {
        color: var(--primary);
    }
}

.flow > * + * {
    margin-top: 1em !important;
}

.flow-half > * + * {
    margin-top: .5em !important;
}

.shadow {
    &-md {
        box-shadow: 0 .5rem 1.5rem .3rem var(--black-shadow);
    }
}
