.btn {
    position: relative;
    padding: 1.4rem;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    gap: .75ch;
    font-weight: 700;
    font-size: 1em;
    line-height: 1;
    text-decoration: none;
    background: none;
    border: 1px solid transparent;
    border-radius: .4rem;
    cursor: pointer;
    transition-property: box-shadow, color, background-color;
    transition-duration: .2s;
    transition-timing-function: ease-in-out;

    > * {
        display: inline-block;
        vertical-align: middle;
        text-underline-offset: .4rem;
    }

    .icon {
        font-size: 1.5em;
    }

    &:not(:disabled):not(.btn-icon) {
        &:hover,
        &:focus-visible {
            span:not(.icon) {
                text-decoration: underline;
            }
        }
    }

    &-primary {
        color: var(--white);
        background-color: var(--primary);

        &:disabled {
            cursor: initial;
            background-color: var(--gray-400);
        }

        &:not(:disabled) {
            background-color: var(--primary);

            &:hover {
                background-color: var(--primary-dark);
            }

            &:focus-visible {
                outline: none;
                background-color: var(--primary-dark);
                box-shadow: 0 0 0 4px var(--primary-shadow);
            }
        }
    }

    &-secondary {
        &:disabled {
            cursor: initial;
            color: var(--gray-400);
            border-color: var(--gray-400);
        }

        &:not(:disabled) {
            color: var(--primary);
            border-color: var(--primary);

            &:hover {
                color: var(--white);
                background-color: var(--primary-dark);
                border-color: var(--primary-dark);
            }

            &:focus-visible {
                outline: none;
                border-color: var(--primary-dark);
                box-shadow: 0 0 0 4px var(--primary-shadow);
            }
        }
    }

    &-empty {
        background: none;
    }

    &-white {
        color: var(--primary);
        background: var(--white);

        &:not(:disabled) {
            &:hover {
                color: var(--white);
                background-color: var(--primary);
            }

            &:focus-visible {
                outline: none;
                box-shadow: 0 0 0 4px var(--primary-shadow);
            }
        }
    }

    &-icon {
        padding: 0;
        height: 5rem;
        aspect-ratio: 1 / 1;
        font-size: 1.5em;
        border-radius: 1.2rem;

        &-lg {
            font-size: 2.3em;
        }

        &.btn-md {
            padding: 0;
            height: 4.2rem;
        }

        &.squeeze {
            aspect-ratio: 1 / 2;
        }
    }

    &-block {
        display: flex;
        width: 100%;
    }

    &-fill-h {
        height: 100%;
    }

    &-pill {
        border-radius: 100vmax;
    }

    &-sm {
        padding: .8rem 1.2rem;
        font-size: .9em;
    }
}

.btn-group {
    border-radius: 1.2rem;
    overflow: hidden;
    display: flex;
    flex-flow: column nowrap;
    width: fit-content;
    margin-inline: auto;

    .btn {
        border-radius: 0;
    }
}

.btn-update {
    text-decoration: none;
    color: var(--primary);
    font-size: 1.05em;
    font-weight: 700;
    line-height: 1.4;
    text-underline-offset: .4rem;
    background: none;
    border: none;
    cursor: pointer;
    &:hover,
    &:focus-visible {
        color: var(--primary-dark);
        text-decoration: underline;
    }

    &:focus-visible {
        outline: none;
    }
}