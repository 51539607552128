.progress {
    position: relative;
    height: 2rem;
    margin-bottom: 1em;
    border-radius: 100vmax;
    box-shadow: 0 0 5px 0 var(--black-shadow);
    isolation: isolate;

    &::before,
    &::after {
        border-radius: inherit;
    }

    &::before {
        content: "";
        position: absolute;
        margin: 2px;
        inset: 0 var(--missing) 0 0;
        background: var(--primary);
        transition: inset .3s ease-in-out;
    }

    &::after {
        content: '';
        position: absolute;
        inset: -0.2rem;
        background-color: var(--white);
        z-index: -1;
        box-shadow: 0 0 3.5rem 0 var(--black-shadow);
    }
}
