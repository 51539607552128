.rank {
    color: var(--primary);
    font-weight: 700;
    display: flex;
    align-items: center;
    gap: .5ch;

    &::after {
        font-family: 'icomoon';
        margin-bottom: .1rem;
        letter-spacing: -0.2ch;
    }

    &.level-1 {
        &::after {
            content: '\e902'
        }
    }
    &.level-2 {
        &::after {
            content: '\e902 \e902'
        }
    }
    &.level-3 {
        &::after {
            content: '\e902 \e902 \e902'
        }
    }
    &.level-4 {
        &::after {
            content: '\e902 \e902 \e902 \e902'
        }
    }
    &.level-5 {
        &::after {
            content: '\e902 \e902 \e902 \e902 \e902'
        }
    }
}
