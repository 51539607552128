.map-view {
    border-radius: 2.4rem;
    overflow: hidden;
    display: grid;
    align-items: end;

    &:not(.square) {
        aspect-ratio: 1 / 1.2;
    }

    &.square {
        max-height: 80vh;
        margin-inline: auto;
        aspect-ratio: 1 / 1;

        img {
            aspect-ratio: 1 / 1;
        }
    }

    > * {
        grid-column: 1 / -1;
        grid-row: 1 / -1;
    }

    img {
        height: 100%;
        width: 100%;
        object-fit: cover;
        object-position: center;
        user-select: none;
        pointer-events: none;
    }

    .map-actions {
        margin-left: 1.6rem;
        margin-bottom: 3.2rem;
        width: fit-content;
    }
}

.marker-pin {
    fill: var(--primary)
}
