@use "libs/normalize";

@use './core/grid';
@use './core/visibility';

@use "fonts";
@use "utilities";
@use "colors";

html {
    box-sizing: border-box;
    font-size: fonts.$size-root;
    -ms-overflow-style: scrollbar;
    height: calc(100vh - 32px);
    -webkit-overflow-scrolling: touch;
}

html *,
html *::before,
html *::after {
    box-sizing: border-box;
    margin: 0;
}

@at-root {
    @-ms-viewport {
        width: device-width;
    }
}

body {
    font-family: fonts.$family-root;
    font-size: fonts.$size-base;
    line-height: fonts.$line-height-base;
    color: var(--body);
    background-color: var(--bg-body);
    min-height: 100%;
    position: relative;
}

:root {
    --overlay-padding-y: 3.2rem;
    --overlay-padding-x: 2.4rem;
}
