.stepper {
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: space-between;
    list-style-position: inside;
    list-style-type: none;
    counter-reset: i;

    li {
        position: relative;
        padding: 1.2rem 0;
        font-weight: 700;
        color: var(--primary);
        opacity: .5;
        counter-increment: i;
        // white-space: nowrap;
        flex: 1 1 0px;
        text-align: center;
        cursor: pointer;

        &::before {
            display: inline-block;
            margin-right: .15rem;
            content: counter(i) '. ';
        }

        &.current {
            opacity: 1;
            cursor: auto;

            &::after {
                content: "";
                display: block;
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
                height: .2rem;
                width: 100%;
                border-radius: 100vmax;
                background-color: var(--primary);
            }
        }
    }
}
