@use "sass:map";
@use '../breakpoints';
@use '../colors';

// Enables simple flex layouts or complex grids
$do-flex: true;
$do-grid: true;

// Grid options
$complex-grid: true; // Enables start and end positionning for single items
$container-w: 1140px !default;
$columns: 12 !default;
$rows: 12 !default;
$col-gap: 10px !default;
$padding: 20px !default;

// Flex options
@mixin box-layouts($grid-layout: false) {
    &.gap {
        @for $i from 0 through 4 {
            &-#{$i} {
                gap: $col-gap * $i;
            }
        }
    }

    @each $bp, $value in breakpoints.$config {
        $bp-min: map.get($value, 'min');
        $bp-max: map.get($value, 'max');

        @if $bp-min {
            @media screen and (width >= $bp-min) {
                .#{$bp}\:order-1 {
                    order: 1;
                }
                .#{$bp}\:order-2 {
                    order: 2;
                }
                .#{$bp}\:order-3 {
                    order: 3;
                }

                &.#{$bp}\:gap {
                    @for $i from 0 through 4 {
                        &-#{$i} {
                            gap: $col-gap * $i;
                        }
                    }
                }
            }
        }
        @if $bp-max {
            @media screen and (width < $bp-max) {
                .#{$bp}\:order-1 {
                    order: 1;
                }
                .#{$bp}\:order-2 {
                    order: 2;
                }
                .#{$bp}\:order-3 {
                    order: 3;
                }

                &.#{$bp}\:gap {
                    @for $i from 0 through 4 {
                        &-#{$i} {
                            gap: $col-gap * $i;
                        }
                    }
                }
            }
        }
    }

    &.align {
        &-center {
            align-items: center;
        }
        &-stretch {
            align-items: stretch;

            .form-group {
                height: 100%;
            }
        }
        &-end {
            align-items: flex-end;
        }
    }

    @if $grid-layout {
        &.justify {
            &-center {
                justify-items: center;
            }
            &-around {
                justify-items: space-around;
            }
            &-between {
                justify-items: space-between;
            }
            &-evenly {
                justify-items: space-evenly;
            }
        }
    } @else {
        &.justify {
            &-center {
                justify-content: center;
            }
            &-around {
                justify-content: space-around;
            }
            &-between {
                justify-content: space-between;
            }
            &-evenly {
                justify-content: space-evenly;
            }
        }
    }
}

.container {
    width: $container-w;
    max-width: 100%;
    margin: 0 auto;
    padding: $padding;

    &.h-full {
        height: 100%;
        max-height: 100%;
    }
}

@if $do-grid {
    .grid {
        display: grid;
        gap: $col-gap;
        grid-template-columns: repeat(auto-fit, minmax(0, 1fr));
        grid-template-rows: repeat(auto-fit, minmax(0, 1fr));

        @include box-layouts($grid-layout: true);

        .align-start {
            align-self: flex-start;
        }
        .align-center {
            align-self: center;
        }
        .align-end {
            align-self: flex-end;
        }

        @for $col from 1 through $columns {
            &.#{"grid-col-" + $col} {
                grid-template-columns: repeat($col, minmax(0, 1fr));
            }

            .#{"col-" + $col} {
                grid-column: span $col / span $col;
            }

            @if $complex-grid {
                .#{"col-start-" + $col} {
                    grid-column-start: $col;
                }

                .#{"col-end-" + $col} {
                    grid-column-end: $col + 1;
                }
            }
        }

        @for $row from 1 through $rows {
            &.#{"grid-row-" + $row} {
                grid-template-rows: repeat($row, minmax(0, 1fr));
            }

            .#{"row-" + $row} {
                grid-row: span $row / span $row;
            }

            @if $complex-grid {
                .#{"row-start-" + $row} {
                    grid-row-start: $row;
                }

                .#{"row-end-" + $row} {
                    grid-row-end: $row + 1;
                }
            }
        }

        @each $bp, $value in breakpoints.$config {
            $bp-min: map.get($value, 'min');
            $bp-max: map.get($value, 'max');

            @if $bp-min {
                @media screen and (width >= $bp-min) {
                    @for $row from 1 through $rows {
                        &.#{$bp}\:#{"grid-row-" + $row} {
                            grid-template-rows: repeat($row, minmax(0, 1fr));
                        }

                        .#{$bp}\:#{"row-" + $row} {
                            grid-row: span $row / span $row;
                        }

                        @if $complex-grid {
                            .#{$bp}\:#{"row-start-" + $row} {
                                grid-row-start: $row;
                            }

                            .#{$bp}\:#{"row-end-" + $row} {
                                grid-row-end: $row + 1;
                            }
                        }
                    }
                    @for $col from 1 through $columns {
                        &.#{$bp}\:#{"grid-col-" + $col} {
                            grid-template-columns: repeat($col, minmax(0, 1fr));
                        }

                        .#{$bp}\:#{"col-" + $col} {
                            grid-column: span $col / span $col;
                        }

                        @if $complex-grid {
                            .#{$bp}\:#{"col-start-" + $col} {
                                grid-column-start: $col;
                            }

                            .#{$bp}\:#{"col-end-" + $col} {
                                grid-column-end: $col + 1;
                            }
                        }
                    }
                }
            }

            @if $bp-max {
                @media screen and (width < $bp-max) {
                    @for $row from 1 through $rows {
                        @for $row from 1 through $rows {
                            &.#{$bp}\:#{"grid-row-" + $row} {
                                grid-template-rows: repeat($row, minmax(0, 1fr));
                            }

                            .#{$bp}\:#{"row-" + $row} {
                                grid-row: span $row / span $row;
                            }
                        }
                        @for $col from 1 through $columns {
                            &.#{$bp}\:#{"grid-col-" + $col} {
                                grid-template-columns: repeat($col, minmax(0, 1fr));
                            }

                            .#{$bp}\:#{"col-" + $col} {
                                grid-column: span $col / span $col;
                            }
                        }
                    }
                }
            }
        }
    }
}

@if $do-flex {
    .flex {
        display: flex;
        gap: 0;

        @include box-layouts();

        &.flow-col {
            flex-flow: column nowrap;
        }

        .grow {
            flex-grow: 1;
        }
    }
}
